<template>
    <div id="app">
        <nav class="navbar navbar-expand navbar-dark bg-dark">
            <a href="/" class="navbar-brand">GesBeques</a>
            <div class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link to="/" class="nav-link"  v-if="!currentUser">
                        <font-awesome-icon icon="home" /> Inici
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                        v-if="currentUser"
                        to="/informes"
                        class="nav-link"
                    >
                        <font-awesome-icon icon="file" /> Informes
                    </router-link>
                </li>
            </div>

            <div v-if="!currentUser" class="navbar-nav ml-auto">
                <li class="nav-item">
                    <router-link to="/login" class="nav-link">
                        <font-awesome-icon icon="sign-in-alt" />
                    </router-link>
                </li>
            </div>

            <div v-if="currentUser" class="navbar-nav ml-auto">

                <li class="nav-item">
                    <a class="nav-link" @click.prevent="logOut">
                        <font-awesome-icon icon="sign-out-alt" /> Sortir
                    </a>
                </li>
            </div>
        </nav>

        <div class="container-fluid">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        showAdminBoard() {
            if (this.currentUser && this.currentUser["roles"]) {
                return this.currentUser["roles"].includes("ROLE_ADMIN");
            }

            return false;
        },
        showModeratorBoard() {
            if (this.currentUser && this.currentUser["roles"]) {
                return this.currentUser["roles"].includes("ROLE_MODERATOR");
            }

            return false;
        },
    },
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
    },
};
</script>